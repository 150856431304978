
import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';
import { FetchPolicy } from 'apollo-client';
import BaseCard from '@/components/commonComponents/BaseCard.vue';
import DataTableHandler from '@/components/DataTable/DataTable';
import EventBus from '@/event-bus';
import LoadingTracker from '@/helpers/LoadingTracker';
import { DocumentNode } from 'graphql';
import Message from '@/components/mixins/Message.vue';
import DataTable from '@/components/DataTable/DataTable.vue';

// Lazy loading imports
const ListAddFields = () => import(
  /* webpackChunkName: 'list-add-fields' */
  /* webpackPrefetch: false */
  '@/components/commonComponents/ListAddFields.vue'
);
const ListBulkActions = () => import(
  /* webpackChunkName: 'list-bulk-actions' */
  /* webpackPrefetch: false */
  '@/components/commonComponents/ListBulkActions.vue'
);
const Links = () => import(
  /* webpackChunkName: 'links' */
  /* webpackPrefetch: false */
  '@/components/commonComponents/Links.vue'
);

@Component({
  name: 'ListBlock',
  components: {
    DataTable,
    ListBulkActions,
    BaseCard,
    ListAddFields,
    Links,
  },
})
export default class ListBlock extends Vue {
  @Prop({ required: true })
  private readonly blockId!: number;

  @Prop()
  private readonly dossierId?: number;

  @Prop({ required: true })
  protected readonly navigationSlug!: string;

  @Prop({ required: true })
  protected readonly dossierTypeSlug!: string;

  @Prop({ required: true })
  private readonly title!: string;

  @Prop()
  private readonly context?: string;

  @Prop()
  private readonly fetchEndpoint?: string;

  @Prop()
  private readonly quickAddEndpoint?: string;

  private loadingTracker = new LoadingTracker();

  private loading = false;

  private dataTable = new DataTableHandler(`listBlock.${this.blockId}`);

  private hasAddFields = false;

  private dialogLinks = [];

  private initialMount = true;

  private hasExport = false;

  private openDialogWhenCreated = false;

  private dialog = undefined;

  isRenderedEvent(target: null|'listAddFields' = null): void {
    if (!this.hasAddFields || target === 'listAddFields') {
      this.$emit('rendered');
    }
  }

  async created(): Promise<void> {
    const forceRerender = () => this.fetchData('network-only');

    EventBus.$on('forceRerender', forceRerender);

    this.$once('hook:beforeDestroy', () => {
      EventBus.$off('forceRerender', forceRerender);
    });
  }

  private refreshData(): void {
    this.fetchData('no-cache');
  }

  private fetchData(fetchPolicy: FetchPolicy = 'cache-first'): void {
    this.loading = true;

    const variables = {
      ...this.dataTable.getQueryVariables(),
      dossierId: this.dossierId,
      navigationSlug: this.navigationSlug,
      dossierTypeSlug: this.dossierTypeSlug,
      blockId: this.blockId,
      context: this.context,
    };

    this.import()
      .then(({ default: query }) => this.$apollo.query({
        fetchPolicy,
        query,
        variables,
      }))
      .then((response) => {
        this.dataTable.handleData(response.data);
        this.hasExport = response.data.list.dataGridInfo.metadata['list-settings']?.export ?? false;
        this.hasAddFields = response.data.list.dataGridInfo.metadata['list-settings']?.quick_add ?? false;
        this.openDialogWhenCreated = response.data.list.dataGridInfo.metadata['list-settings']?.form_zoom_after_add ?? false;
        this.dialog = response.data.list.dataGridInfo.metadata['list-settings']?.link?.data?.dialog ?? undefined;
        this.dialogLinks = response.data.list.dataGridInfo.metadata['list-settings']?.dialog_links ?? [];
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      })
      .finally(() => {
        this.loading = false;
        this.isRenderedEvent();
      });
  }

  private exportFile(): void {
    this.loading = true;

    const variables = {
      filters: this.dataTable.getQueryVariables().filters,
      sorters: this.dataTable.getQueryVariables().sorters,
      dossierId: this.dossierId,
      dossierTypeSlug: this.dossierTypeSlug,
      navigationSlug: this.navigationSlug,
      blockId: this.blockId,
      context: this.context,
    };
    import('@/graphql/queries/export-dossier-type-list-block-by-id')
      .then(({ default: query }) => this.$apollo.query({
        query,
        variables,
      }))
      .then((response) => {
        const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response.data.export.data}`;
        const downloadLink = document.createElement('a');
        const fileName = response.data.export.name;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private openDialogOnCreation(itemId: string) {
    if (!this.openDialogWhenCreated) {
      return;
    }

    if (typeof this.dialog === 'undefined') {
      return;
    }

    const url = `view-${this.dialog}/${itemId}`;

    let currentHash = this.$route.hash.replace('#', '');
    if (currentHash.length === 0) {
      currentHash = window.location.hash.replace('#', '');
    }

    let newHash = currentHash;
    if (newHash.length > 0) {
      newHash = `${currentHash}|${url}`;
    } else {
      newHash = url;
    }

    this.$router.push({
      hash: `#${newHash}`,
    });
  }

  private import(): Promise<{ readonly default: DocumentNode }> {
    return import(`@/graphql/queries/${this.fetchEndpoint ?? 'dossier-type-list-block-by-id'}`);
  }
}
