export default class LoadingTracker {
  public loading = false;

  public loadingCounter = 0;

  public trackLoading(isLoading: boolean): void {
    if (isLoading === true) {
      this.loadingCounter += 1;
    } else if (isLoading === false) {
      this.loadingCounter -= 1;
    }

    this.loading = this.loadingCounter > 0;
  }
}
